<template>
  <div class="view pa24">
    <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-position="top"
        class="demo-ruleForm"
    >
      <el-form-item label="商户id" prop="appName">
        <el-col :lg="11">
          <el-input
              :placeholder="isSucceed('name')"
              v-model="ruleForm.merchantId"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="商户秘钥" prop="appId">
        <el-col :lg="11">
          <el-input
              :placeholder="isSucceed('Id')"
              v-model="ruleForm.key"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="支付证书" prop="AppSecret">
        <el-col :lg="24" class="mb20">
          <el-upload
              class="upload-demo"
              :action="uploadUrl"
              :before-upload="beforeUpload"
              :before-remove="beforeRemove"
              :limit="1"
              :on-exceed="handleExceed"
              :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能p12证书,从微信支付商户获取</div>
          </el-upload>
        </el-col>
        <el-col :lg="24">
          <el-button type="primary" @click="submitPayInfo" :disabled="buttonFlag">提交</el-button>
        </el-col>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>
import {componentloginpage} from "@/api/authorization"
import {submitPayInfo2} from '@/api/wechat'
import {Message} from 'element-ui'
import {getGoodsInfoPC} from "@/api/videoManage";
import {getAccountById} from "@/api/authorization"
export default {
  data() {
    return {
      accountMap: '',
      buttonFlag:false,
      uploadUrl: this.$store.state.uploadingUrl,
      //文件数组
      fileList: [],
      ruleForm: {
        merchantId: '',
        key: '',
        certData: '',
      },
      rules: {
        merchantId: [
          {required: true, message: "请输入商户ID", trigger: "blur"},
        ],
        key: [
          {required: true, message: "请输入商户秘钥", trigger: "blur"},
        ],
        certData: [
          {required: true, message: "请上传支付证书", trigger: "blur"},
        ],
      },
      idMap: {
        1: 'userApp',
        2: 'crmApp',
        3: 'pcData',
        4: 'payInfo',
      },
    };
  },
  created() {
    let crmInfo = JSON.parse(localStorage.getItem('crmInfo')) || ''
    if (crmInfo) {
      this.ruleForm = crmInfo
    }
    this.getAppListInfo()
  },
  computed: {
    checkAppSecret() {
      return this.ruleForm.AppSecret ? '' : 'password'
    }
  },
  methods: {
    async getAppListInfo() {
      const result = await getAccountById({appid: true,accountId:195})
      if (result.data) {
        if (result.data[0].state==0) {
          this.getGoodsInfoPC();
        }else {
          this.buttonFlag = false
        }
        let tmp = {};
        result && result.data.map(val => {
          tmp[val.accountType] = val;
        });
        this.accountMap = tmp;
      }
    },
    /**@method 加载商品列表 */
    async getGoodsInfoPC() {
      let data = {
        pageSize: 999,
        pageNum: 1,
      };
      try {
        const result = await getGoodsInfoPC(data);
        const {total, list} = JSON.parse(result?.data?.pageInfo);
        if (list.length != 0) {
          this.dialogVisibleD = true
          this.buttonFlag = true
        }else {
          this.buttonFlag = false
        }
      } catch (error) {
      }
    },
    handleExceed() {

    },
    beforeUpload(file) {
      console.log(file)
      const that = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        let base64Data = e.target.result;
        base64Data = base64Data.substring(base64Data.indexOf(',') + 1);
        that.ruleForm.certData = base64Data;
      };
    },
    beforeRemove() {

    },
    handlePreview() {

    },
    isSucceed(type) {
      let str = (type == 'Id') ? '请上传商户密钥' : '请上传商户id'
      for (var i in this.accountMap) {
        if (this.accountMap[i].accountType == 4) {
          if (this.accountMap[i].state == 1) {
            str = (type == 'Id') ? '上传成功' : this.accountMap[i].merchantId
          } else {
            str = (type == 'Id') ? '请上传商户密钥' : '请上传商户id'
          }
        }
      }
      return str
    },
    submitPayInfo() {
      if (!this.ruleForm.merchantId || !this.ruleForm.key || !this.ruleForm.certData) {
        return this.$message.error('请检查资料是否完整')
      }
      let data = {
        merchantId: this.ruleForm.merchantId,
        key: this.ruleForm.key,
        certData: this.ruleForm.certData,
        accountType: 4, //用户支付
        accountId:195
      }

      submitPayInfo2(data).then(res => {
        Message({message: "上传成功", type: 'success'})
      })
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-upload--text {
  background-color: #fff;
  border: 0px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 84px;
  height: 34px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
</style>

