import httpUtil from '../utils/httpUtil'

/**
 * 小程序管理
 * @param params
 * @returns {Promise<R>}
 */
export const componentCallBack = params => httpUtil.post("/api/crmPc/component/componentCallBack", params);

export const submitPayInfo = params => httpUtil.post("/api/crmPc/companyGoods/payment", params);
export const submitPayInfo2 = params => httpUtil.post("/api/crmPc/companyGoods/payment2", params);

/**
 * 企业登录强制授权
 *
*/
export const switchCompanyModel = params => httpUtil.post("/api/crmPc/companyGoods/switchCompanyModel", params);

/**
 * 查询企业登录强制授权
 *
*/
export const querySwitchCompanyModel = params => httpUtil.post("/api/crmPc/companyGoods/querySwitchCompanyModel", params);


/**
 * 按公司查询菜单基础配置
*/
export const queryTabMenu = params => httpUtil.post("/api/crmPc/tabMenu/queryTabMenu", params);


/**
 * 添加或者更新公司菜单
*/
export const saveTabMenu = params => httpUtil.post("/api/crmPc/tabMenu/saveTabMenu", params);



/**
 * 保存商城模板
*/
export const saveShopModelConfig = params => httpUtil.post("/api/crmPc/shopConfig/saveShopModelConfig", params);


/**
 * 更新商城模板
*/
export const updateShopModelConfig = params => httpUtil.post("/api/crmPc/shopConfig/updateShopModelConfig", params);


/**
 * 获取商城模板
*/
export const queryShopModelConfig = params => httpUtil.post("/api/crmPc/shopConfig/queryShopModelConfig", params);


/**
 * 获取所有模板信息
*/
export const queryShopModelConfigAll = async (params,type) => httpUtil.post("/api/crmPc/shopConfig/queryShopModelConfigAll", params,type);



/**
 * 保存商城基础配置
*/
export const saveShopConfig = params => httpUtil.post("/api/crmPc/shopConfig/saveShopConfig", params);


/**
 * 查询商城基础配置
*/
export const queryShopConfig = params => httpUtil.post("/api/crmPc/shopConfig/queryShopConfig", params);



